import React, { useState, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import { Row, Col } from "react-bootstrap";
import ModalServices from "./ModalServices";

// import dbData from "../data/db";

const imgUrl = require.context('../../assets/images', true);

const CardService = (props) => {

    const dbData = useContext(GlobalContext);
    console.log('services', dbData);

    const [modalContent, setModalContent] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (img, title, description, id) => {
        let temData = [img, title, description, id];
        console.log(temData);
        setTempdata(itme => [1, ...temData]);
        return setModalContent(true)
    }

    const getDescription = (item, index) => {
        const classes = index % 2 === 0 ? 'modalServicesColumna' : 'modalServicesColumnaReverse'
        return (
            <Row key={index} className={classes}>
                <Col md={12} lg={6} className='imgModalContent'>
                    <img src={item.img} alt='services' style={{ width: '100%' }} />
                </Col>
                <Col md={12} lg={6} className='infoModalContent'>
                    <div>
                        <p>{item.text}</p>
                        {
                            item.lists[0] !== ' ' ?
                                <ul>
                                    {
                                        item.lists.map((items, index) => {
                                            return (
                                                <li key={index}>{items.lists}</li>
                                            )
                                        })
                                    }
                                </ul>
                                : null
                        }
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <Row>
            {
                dbData.dbServices.map((service, index) => {
                    return (
                        <Col key={index} sm={12} md={6} lg={4} className="mt-5">
                            <div className="cause-card">
                                <div className="cause-card__inner">
                                    <div className="cause-card__image">
                                        <img
                                            src={service.description[0].img}
                                            height={250}
                                            alt="services"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                getData(
                                                    service.image,
                                                    service.name,
                                                    service.description.map((item, index) => getDescription(item, index)),
                                                    service.id,
                                                )}
                                        />
                                    </div>
                                    <div className="cause-card__content">
                                        <div className="cause-card__top">
                                            <h3
                                                style={{ marginTop: '-30px', fontWeight:'bold'}}
                                            >
                                                {service.name}
                                            </h3>
                                        </div>
                                        <p>{service.description[0].text.substring(0, 130) + "..."}</p>
                                        <div className="cause-card__bottom">
                                            <button className="thm-btn text-capitalize"
                                                onClick={() =>
                                                    getData(
                                                        service.image,
                                                        service.name,
                                                        service.description.map((item, index) => getDescription(item, index)),
                                                        service.id,
                                                    )}
                                            >
                                                more services
                                            </button>
                                            <a className="cause-card__share" href={`tel:+1${dbData.dbPrincipal.phones[0].phone}`}>
                                                <i className="azino-icon-telephone"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            {
                modalContent === true ? <ModalServices img={tempdata[1]} title={tempdata[2]} description={tempdata[3]} hide={() => setModalContent(false)} /> : ''
            }
        </Row>
    )
}

export default CardService;