import { useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import * as FaIcons from 'react-icons/fa';




function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <div className="footer-social">
            {
                dbData.dbSocialMedia.redes.map((item, index) => {
                    const Social =
                        FaIcons[
                        item.icon
                        ];
                    return (
                        <>
                            {
                                item.name !== ' ' ?
                                    <a
                                        key={index}
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Social />
                                    </a>
                                    : null
                            }
                        </>
                    )
                })
            }
        </div>
    );
}

export default RedesContent;