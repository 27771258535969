import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";

const GoogleMap = ({ extraClass }) => {
    const dbData = useContext(GlobalContext);
    return (
        <div className={`google-map__${extraClass}`}>
            <iframe
                title="template google map"
                src={`${dbData.dbPrincipal.location[0].url}`}
                className={`map__${extraClass}`}
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default GoogleMap;