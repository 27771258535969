import { Link } from "react-router-dom";

const PagesHeader = ({ title, crumbTitle, pageHeaderBg }) => {

    return (
        <section className="page-header">
            <div
                className="page-header__bg"
                style={{ backgroundImage: `url("${pageHeaderBg}")` }}
            ></div>

            <div className="container">
                <h2 className="text-capitalize fw-bold">{title}</h2>
                <ul className="thm-breadcrumb list-unstyled ">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>-</li>
                    <li>
                        <span>{crumbTitle}</span>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default PagesHeader;