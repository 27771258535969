import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";

import { Container, Row, Col } from "react-bootstrap";

import { FiPhoneCall } from "react-icons/fi";
import IconTitle from "../IconTitle";

const AboutOne = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="about-one pt-120 pb-40">
            <Container>
                <Row>
                    <div className="about-one__award">
                        <a href={`tel:+1${dbData.dbPrincipal.phones[0].phone}`}>
                            <FiPhoneCall fontSize={90} className='text-white' />
                        </a>
                    </div>
                    <Col lg={6}>
                        <img src={dbData.stock[16]} alt="" className="img-fluid"/>
                    </Col>
                    <Col lg={6}>
                        <img src={dbData.stock[13]} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="team-about__top mt-60">
                    <Row>
                        {
                            dbData.dbValues.map((value, index) => {
                                return (
                                    <Col lg={4} key={index}>
                                        <div className="block-title">
                                            <IconTitle
                                                tagLine={value.title}
                                            />
                                        </div>
                                        <p className="team-about__top-text pt-3">
                                            {value.description}
                                        </p>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </div>
            </Container>
        </section>
    );
}
export default AboutOne;