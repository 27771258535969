import { useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';

import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ModalServices from './ModalServices';


const CardServicesTwo = () => {
    const dbData = useContext(GlobalContext);

    const [modalContent, setModalContent] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (img, title, description, id) => {
        let temData = [img, title, description, id];
        console.log(temData);
        setTempdata(itme => [1, ...temData]);
        return setModalContent(true)
    }

    const getDescription = (item, index) => {
        const classes = index % 2 === 0 ? 'modalServicesColumna' : 'modalServicesColumnaReverse'
        return (
            <Row key={index} className={classes}>
                <Col md={12} lg={6} className='imgModalContent'>
                    <img src={item.img} alt='services' style={{ width: '100%' }} />
                </Col>
                <Col md={12} lg={6} className='infoModalContent'>
                    <div>
                        <p>{item.text}</p>
                        {
                            item.lists[0] !== ' ' ?
                                <ul>
                                    {
                                        item.lists.map((items, index) => {
                                            return (
                                                <li key={index}>{items.lists}</li>
                                            )
                                        })
                                    }
                                </ul>
                                : null
                        }
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <Row className='pb-50'>
            {
                dbData.dbServices.slice(0, 6).map((service, index) => {
                    return (
                        <Col key={index} sm={12} md={6} className="mt-5">
                            <div className="event-card">
                                <div className="event-card-inner">
                                    <div className="event-card-image">
                                        <div className="event-card-image-inner">
                                            <img src={service.description[0].img} width='130px' height='130px' alt="" />
                                            <a href={`tel:+1${dbData.dbPrincipal.phones[0].phone}`}>
                                                <span>
                                                    <i class="fa fa-phone" aria-hidden="true" style={{ fontSize: '20px' }}></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="event-card-content">
                                        <h3 className='pb-4'>
                                            <Link to='/contact'>{service.name}</Link>
                                        </h3>
                                        <p>{service.description[0].text.substring(0, 100) + "..."}</p>
                                        <button
                                            className="thm-btn dynamic-radius text-capitalize"
                                            onClick={() =>
                                                getData(
                                                    service.image,
                                                    service.name,
                                                    service.description.map((item, index) => getDescription(item, index)),
                                                    service.id,
                                                )}
                                        >
                                            More Services
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            {
                modalContent === true ? <ModalServices img={tempdata[1]} title={tempdata[2]} description={tempdata[3]} hide={() => setModalContent(false)} /> : ''
            }
        </Row>
    )
};

export default CardServicesTwo;